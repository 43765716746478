import { useQuery } from "@tanstack/react-query";

import { getUserTokenFromCookie } from "@aether/shared/services/util-service";
import { getWallet } from "@aether/shared/services/wallet-service";
import { useAuth } from "@aether/shared/context/auth";

export const getWalletKey = "get-wallet-key";

export default function useWallet() {
  const { user, isLoading } = useAuth();

  const fetchWallet = useQuery({
    queryFn: async () => await getWallet(getUserTokenFromCookie()),
    enabled: !isLoading && Boolean(user),
    queryKey: [getWalletKey],
  });

  return { fetchWallet };
}
