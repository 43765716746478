import { Button } from "@/components/ui/button";
import Link from "next/link";
import { useAuth } from "@aether/shared/context/auth";

export function PresignupButton() {
  const { user, isLoading } = useAuth();

  if (user && !isLoading) {
    return null;
  }

  return (
    <Link href="#presignup">
      <Button className="bg-aether-blue hover:bg-aether-blue/80">
        PreSign Up!
      </Button>
    </Link>
  );
}
