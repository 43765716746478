"use client";
import { Logo } from "@/components/atoms/logo";
import AuthButton from "@/components/molecules/auth-button";
import { PresignupButton } from "@/components/molecules/presignup-button";

import { useAuth } from "@aether/shared/context/auth";
import CartSheet from "../molecules/cart-sheet";
import { Gem, X } from "lucide-react";
import useWallet from "@/hooks/use-wallet";
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogTrigger } from "@/components/ui/dialog";
import { Button } from "@/components/ui/button";
import { usePayment } from "@/hooks/use-payment";
import { useRouter } from "next/navigation";

export function Token() {
  const {
    fetchWallet: { data },
  } = useWallet();
  const { executePayment } = usePayment();
  const router = useRouter();

  return (
    <div className="flex items-center gap-1">
      <p className="font-semibold">
        {data && data.token > 0 ? data.token : null}
      </p>
      <Dialog>
        <DialogTrigger>
          <Gem className="text-green-600" />
        </DialogTrigger>
        <DialogContent className="max-w-xs p-0 overflow-hidden gap-0">
          <DialogHeader className="p-3 bg-stone-100 border-b-1 m-0">
            <DialogTitle>Buy Tokens</DialogTitle>
          </DialogHeader>
          <div className="p-3 flex flex-col justify-center items-center">
            <div className="flex flex-col justify-center items-center w-fit">
              <Button
                variant="secondary"
                className="border rounded-lg flex p-5 text-lg items-center gap-1"
                onClick={async (event) => {
                  event.preventDefault();
                  try {
                    const res = await executePayment.mutateAsync();
                    router.push(res.checkout_url);
                  } catch (e) {
                    console.log(e)
                  }
                }}
              >
                4 <X className="h-4 w-4" /> <Gem className="text-green-600" />
              </Button>
              <p className="text-center font-semibold">₱100</p>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </div >
  );
}

export default function Header() {
  const { user, isLoading } = useAuth();

  return (
    <header className="container w-full py-2 flex items-center justify-between">
      <Logo />
      <nav className="flex gap-4 items-center">
        {user && !isLoading ? (
          <div className="flex items-center gap-3">
            <Token />
            <CartSheet />
          </div>
        ) :
          !isLoading && !user ? <PresignupButton /> : null
        }

        <AuthButton />
      </nav>
    </header>
  );
}
