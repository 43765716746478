import { useMutation, useQueryClient } from "@tanstack/react-query";
import { getUserTokenFromCookie } from "@aether/shared/services/util-service";
import {
  createPhotoOrder,
  createWalletOrder,
} from "@aether/shared/services/payment-service";
import { getWalletKey } from "./use-wallet";
import { getCartKey } from "./use-cart";

export const usePayment = () => {
  const queryClient = useQueryClient();

  const executePayment = useMutation({
    mutationFn: async () => await createWalletOrder(getUserTokenFromCookie()),
  });

  const executePhotoClaim = useMutation({
    mutationFn: async () => await createPhotoOrder(getUserTokenFromCookie()),
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: [getWalletKey],
      });
      await queryClient.invalidateQueries({
        queryKey: [getCartKey],
      });
    },
  });

  return { executePayment, executePhotoClaim };
};
