import {
  Sheet,
  SheetContent,
  SheetFooter,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
} from "@/components/ui/sheet";
import { Gem, ShoppingCartIcon } from "lucide-react";
import { Button } from "@/components/ui/button";
import useCart from "@/hooks/use-cart";
import Image from "next/image";
import { format } from "date-fns";
import { Cross2Icon } from "@radix-ui/react-icons";
import provinceData from "@/utils/provinces.json";
import useWallet from "@/hooks/use-wallet";
import { usePayment } from "@/hooks/use-payment";
import { useState } from "react";
import { toast } from "sonner";
import { useRouter } from "next/navigation";

export default function CartSheet() {
  const {
    fetchCart: { data, isLoading },
    deleteCart,
  } = useCart();
  const {
    fetchWallet: { data: walletData, isLoading: isWalletLoading },
  } = useWallet();

  const { executePhotoClaim } = usePayment();
  const [claimLoading, setClaimLoading] = useState<boolean>(false);
  const [open, setOpen] = useState(false);
  const router = useRouter();

  return (
    <Sheet open={open} onOpenChange={setOpen}>
      <SheetTrigger asChild>
        <button className="relative">
          {data?.cart.cartItems.length ? (
            <span className="bg-sky-300 text-white font-semibold rounded-full w-4 h-4 flex items-center justify-center absolute -right-2 -top-1 text-xs">
              {data?.cart.cartItems.length}
            </span>
          ) : null}
          <ShoppingCartIcon className="w-6 h-6" />
        </button>
      </SheetTrigger>
      <SheetContent>
        <SheetHeader>
          <SheetTitle>My Cart</SheetTitle>
          <hr />
        </SheetHeader>
        <div className="flex flex-col justify-between h-full pt-4 pb-10">
          {!isLoading && data ? (
            <div className="grid overflow-y-auto gap-4 py-2">
              {data.cart.cartItems.map((cartItem) => (
                <div
                  className="flex gap-4 relative border rounded-sm overflow-hidden group hover:bg-neutral-50"
                  key={cartItem.id}
                >
                  <div className="aspect-square h-24 w-24 relative">
                    {/* eslint-disable-next-line @next/next/no-img-element */}
                    <img
                      alt="cart item photo"
                      className="aspect-square object-cover"
                      height={500}
                      width={500}
                      src={cartItem.photo.key}
                    />
                  </div>
                  <div className="gap-2 py-1">
                    <p className="font-semibold">
                      {
                        provinceData.provinces.find(
                          (province) =>
                            province.id === cartItem.photo.collection.province,
                        )?.name
                      }
                    </p>
                    <p className="font-light text-sm">
                      {format(cartItem.photo.collection.date, "PPP")}
                    </p>
                    <p className="mt-1">{cartItem.photo.collection.name}</p>
                  </div>
                  <button
                    title="Remove cart item"
                    className="absolute right-2 top-2 rounded-full bg-neutral-100 group-hover:bg-sky-300 p-1"
                    onClick={() => {
                      deleteCart.mutate({ photoId: cartItem.photoId });
                    }}
                  >
                    <Cross2Icon className="h-4 w-4 text-red-500 group-hover:text-white" />
                  </button>
                </div>
              ))}
            </div>
          ) : (
            <div className="flex items-center justify-center">
              No items on cart!
            </div>
          )}

          <SheetFooter>
            <div className="flex flex-col w-full gap-4">
              <hr />
              <div className="flex justify-between">
                <h1 className="font-bold text-xl leading-tight">Subtotal</h1>
                <span className="font-semibold flex items-center gap-1">
                  <p>{!isLoading && data ? data.info.subtotal : 0}</p>
                  <Gem className="text-green-500 h-5" />
                </span>
              </div>
              {walletData &&
                data &&
                (walletData.token <= 0 ||
                  walletData.token < data.info.subtotal) ? (
                <p className="text-center text-red-500 text-xs font-semibold">
                  You don&apos;t have enough token to claim photos
                </p>
              ) : null}

              <Button
                type="button"
                onClick={async () => {
                  setClaimLoading(true);
                  try {
                    await executePhotoClaim.mutateAsync();
                    toast.success("Photos are now claimed!");
                    setClaimLoading(false);
                    setOpen(false);
                    router.push("/orders");
                  } catch (e) {
                    toast.error("Failed to claim photos");
                    setClaimLoading(false);
                  }
                }}
                disabled={
                  isLoading ||
                  isWalletLoading ||
                  !data ||
                  !walletData ||
                  walletData.token <= 0 ||
                  walletData.token < data.info.subtotal ||
                  claimLoading
                }
              >
                Claim Photos
              </Button>
            </div>
          </SheetFooter>
        </div>
      </SheetContent>
    </Sheet>
  );
}
