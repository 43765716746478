import Link from "next/link";
import { cn } from "@/lib/utils";
import Image from "next/image";

export const Logo = () => {
  return (
    <div>
      <Link href="/" className={cn("font-bold flex items-center gap-2")}>
        <Image
          src="/aether-logo.png"
          alt="Aether Logo"
          width={400}
          height={400}
          className="h-12 w-12"
        />
        <Image
          src="/aether-logo-text.png"
          alt="Aether Logo"
          width={400}
          height={400}
          className="h-6 w-auto"
        />
      </Link>
    </div>
  );
};
